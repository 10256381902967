<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container-fluid" v-if="$store.getters.user">
          <backBtn :settings="{block:true}" :onClick="function() { goBackToDashboard() }">
            <template v-slot:text>
              {{ $t('message.dashboard') }}
            </template>
          </backBtn>
        </div>
        <div class="flix-container-fluid">
          <shares />
        </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
export default {
  components: {
    shares () { return import('@/components/shares/dashboard.vue') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {
    goBackToDashboard () {
      this.$router.push({ name: 'dashboard' })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
